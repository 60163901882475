@use "../../styles/abstracts-var-mixins/mixins" as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  *::before,
  *::after {
    box-sizing: border-box;
  }
  // check this later
  -webkit-font-smoothing: antialiased;
  font-family: "Poppins", sans-serif;
}
