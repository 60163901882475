@use "../../styles/abstracts-var-mixins/variables" as *;
@use "../../styles/abstracts-var-mixins/mixins" as *;

.hexagon {
  font-family: $header-font-family;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 15rem;
  text-align: center;
  color: $white;

  .hexagon-image {
    height: 100%;
    width: 100%;
    transition: fill 1.3s ease-in;
  }

  .hexagon__text-container {
    width: 60%;
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    left: 0;
    right: 0;
    margin: auto;
    align-items: center;
    padding: 0.7rem;

    .hexagon__title {
      margin-bottom: 0.2rem;
      @include subheader-text;
      font-size: 1rem;
      display: inline-block;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: currentColor;
        transform-origin: bottom right;
        transition: transform 0.6s ease-out;
      }
      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
    .hexagon__sub-title {
      text-align: center;
      width: 100%;
      @include header-text;
      font-size: 0.8rem;
    }
  }
  &:hover {
    color: $black;
    font-weight: 300;

    .hexagon-image--m {
      fill: darken($orange, 20%);
    }
    .hexagon-image--rail {
      fill: darken(rgb(84, 219, 163), 20%);
    }
    .hexagon-image--ground {
      fill: darken($orange-medium, 20%);
    }
  }
}
