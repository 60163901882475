@use "../../styles/abstracts-var-mixins/variables" as *;
@use "../../styles/abstracts-var-mixins/mixins" as *;

.footer {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  gap: 0.4rem;
  background-color: $white;
  padding: 1rem;
  .footer__tagline-container {
    grid-row: 1 / 2;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    .footer__tagline {
      font-size: 0.9rem;
      font-weight: $semibold-font-weight;
    }
    .footer_logo-container {
      width: 50%;

      .footer__logo {
        width: 10rem;
      }
    }
  }
  .footer__site-links-container {
    grid-row: 2 / 3;
    & > div {
      .site-link-list__link--footer {
        text-decoration: none;
        @include subheader-text;
        font-weight: $light-font-weight;
        font-size: 0.8rem;
        &:hover {
          font-weight: 600;
          text-decoration: underline $orange-light;
        }
      }
    }
  }
  .footer__contact-us-container {
    grid-row: 3 / 4;
  }

  h5 {
    font-size: 1rem;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .footer {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-around;

    .footer__site-links-container {
      text-align: center;
      & > div {
        .site-link-list__link--footer {
          display: flex;
          justify-content: center;
        }
      }
    }
    .footer__contact-us-container {
      & > div > a > h5 {
        text-align: center;
      }
    }
    .footer__social-links-container {
      max-width: 8rem;
    }
  }
}

@media screen and (min-width: $large-desktop-breakpoint) {
  .footer {
    & > div {
      margin: 1rem;
    }
  }
}
