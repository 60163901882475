@use "../../styles/abstracts-var-mixins/variables" as *;
@use "../../styles/abstracts-var-mixins/mixins" as *;

.hero-section {
  display: flex;
  flex-flow: column wrap;

  background-color: $grey-light;

  .hero-section__text-container {
    padding: 0.8rem;
    width: 100%;
    color: $black;
    .hero-section__header {
      text-decoration: 3px underline $orange-light;
      font-size: 1.8rem;
    }
    .hero-section__sub-header {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
  .hero-section__icon-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: $tablet-breakpoint) {
  .hero-section {
    flex-flow: row nowrap;
    background-image: url("../../images/assets/trainStation.jpeg");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;

    .hero-section__text-container {
      background-color: $grey-light;
      clip-path: circle(70.6% at 0 51%);
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      width: 40%;
      flex-grow: 2;
    }
    .hero-section__icon-container {
      padding: 0rem;
      flex-flow: row wrap;
    }
  }
}
@media screen and (min-width: 1200px) {
  .hero-section {
    background-attachment: fixed;
    
    .hero-section__text-container {
      clip-path: circle(90.6% at 0 51%);

    }
  }
}

@media screen and (min-width: $desktop-breakpoint) {
  .hero-section {
    .hero-section__icon-container {
      max-width: 900px;
    }
  }
}

@media screen and (min-width: 1600px) {
  .hero-section {
    flex-grow: 0;
    .hero-section__text-container {
      align-items: center;
      .hero-section__header,
      .hero-section__sub-header {
        max-width: 450px;
      }
    }
  }
}
@media screen and (min-width: 2000px) {
  .hero-section {
    flex-grow: 0;
    .hero-section__text-container {
      clip-path: circle(100% at 0 51%);
      align-items: center;
      .hero-section__header,
      .hero-section__sub-header {
        max-width: 700px;
      }
      .hero-section__header {
        font-size: 3rem;
      }
      .hero-section__sub-header {
        font-size: 1.8rem;
      }
    }
  }
}
