@use "../../styles/abstracts-var-mixins/variables" as *;
@use "../../styles/abstracts-var-mixins/mixins" as *;
.site-link-list__link {
  text-decoration: none;
  text-align: center;
  padding: 0.1rem;
  &:nth-child(n) {
    color: $black;
    &:hover {
      font-weight: 600;
      text-decoration: underline $orange-light;
    }
  }
  &:last-child {
    font-weight: 600;
  }
}
