.decorative-banner {
  background: linear-gradient(
    130deg,
    #ffffff,
    #ff8b53,
    #ff8b53,
    #e8e8e8,
    #ffffff
  );
  background-size: 600% 600%;

  -webkit-animation: decorativeBannerGradient 7s ease infinite;
  -moz-animation: decorativeBannerGradient 7s ease infinite;
  animation: decorativeBannerGradient 7s ease infinite;
  &--black {
    background: linear-gradient(130deg, #ffffff, #1e1e1e, #ffffff, #1e1e1e);
    background-size: 600% 600%;

    -webkit-animation: decorativeBannerGradient 12s ease infinite;
    -moz-animation: decorativeBannerGradient 12s ease infinite;
    animation: decorativeBannerGradient 12s ease infinite;
  }
  &--white {
    background: linear-gradient(
      130deg,
      #ffffff,
      #e8e8e8,
      darken(#e8e8e8, 30%),
      #e8e8e8,
      #f4f4f4
    );
    background-size: 600% 600%;

    -webkit-animation: decorativeBannerGradient 7s ease infinite;
    -moz-animation: decorativeBannerGradient 7s ease infinite;
    animation: decorativeBannerGradient 7s ease infinite;
  }
  &--lightYellow {
    background: linear-gradient(
      130deg,
      #ffffff,
      #ffc85c,
      #ffffff,
      #ffc85c,
      #ffffff
    );
    background-size: 600% 600%;

    -webkit-animation: decorativeBannerGradient 7s ease infinite;
    -moz-animation: decorativeBannerGradient 7s ease infinite;
    animation: decorativeBannerGradient 7s ease infinite;
  }
  &--green {
    background: linear-gradient(
      130deg,
      #ffffff,
      rgb(84, 219, 163),
      #ffffff,
      rgb(84, 219, 163),
      #ffffff
    );
    background-size: 600% 600%;

    -webkit-animation: decorativeBannerGradient 7s ease infinite;
    -moz-animation: decorativeBannerGradient 7s ease infinite;
    animation: decorativeBannerGradient 7s ease infinite;
  }
}

@-webkit-keyframes decorativeBannerGradient {
  0% {
    background-position: 20% 0%;
  }
  50% {
    background-position: 81% 100%;
  }
  100% {
    background-position: 20% 0%;
  }
}
@-moz-keyframes decorativeBannerGradient {
  0% {
    background-position: 20% 0%;
  }
  50% {
    background-position: 81% 100%;
  }
  100% {
    background-position: 20% 0%;
  }
}
@keyframes decorativeBannerGradient {
  0% {
    background-position: 20% 0%;
  }
  50% {
    background-position: 81% 100%;
  }
  100% {
    background-position: 20% 0%;
  }
}
