// Global Color palette

// Base colours
$black: #1e1e1e;
$white: #ffffff;
$grey-light: #f4f4f4;
$grey-medium: #e8e8e8;

// highlight colours

// M&E construction
$orange: #ff8b53;
// Ground Engineering
$orange-medium: #febe68;
// railSystems
$orange-light: #ffc85c;
// Rail Systems NEW
$green: rgb(84, 219, 163);
// Global font properties
$header-font-family: "Poppins", sans-serif;
$text-font-family: "Montserrat", Verdana;

// font sizes
$large-header-font-size: 3rem;
$header-font-size: 2rem;
$subheader-font-size: 1.5rem;
$paragraph-font-size: 1rem;
$small-paragraph-font-size: 0.9rem;

// font-weights
$light-font-weight: 300;
$semibold-font-weight: 600;

// media query breakpoints
$mobile-breakpoint: 320px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1024px;
$large-desktop-breakpoint: 1440px;
$extra-large-desktop-breakpoint: 2560px;
