@use "../../styles/abstracts-var-mixins/variables" as *;
@use "../../styles/abstracts-var-mixins/mixins" as *;

.dropdown-menu {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  position: absolute;
  top: 4rem;
  background-color: $grey-light;
  z-index: 300;
  & > a {
    @include subheader-text;
    font-size: 1rem;
    text-decoration: 1px underline $orange-light;
    border: 1px solid $grey-medium;
    border-width: 1px 0px;
    padding: 0.4rem 0.4rem 0.4rem 2rem;
    color: $black;
    text-align: left;
  }
  & :first-child {
    border-top: 2px solid darken($grey-medium, 10%);
  }
  & :last-child {
    border-bottom: 2px solid darken($grey-medium, 10%);
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .dropdown-menu--header {
    display: none;
  }
}
