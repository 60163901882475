@use "../../styles/abstracts-var-mixins/variables" as *;
.banner {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  background-color: $white;

  .banner__logo-container {
    padding-right: 1rem;
    flex-grow: 0.3;
    .banner__logo {
      width: 100%;
      height: 2rem;
    }
  }
  .banner__nav-menu-container {
    flex-grow: 0, 5;
    display: none;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-end;
    width: 50%;

    .banner__links-container {
      flex-grow: 1;
      padding: 0.5rem 1rem;
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: $orange-medium;
    }
  }
  .banner__hamburger-menu,
  .banner_dropdown-menu-close {
    padding: 0.1rem;
    font-size: 2rem;
    background-color: inherit;
    color: black;
    border-radius: 0.5rem;
  }
  .banner_dropdown-menu-close {
    color: $orange-light;
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .banner {
    padding: 1rem 3rem;
    .banner__logo-container {
      padding-right: 0rem;
      .banner__logo {
        height: auto;
      }
    }
    .banner__nav-menu-container {
      display: flex;
    }
    .banner__hamburger-menu,
    .banner_dropdown-menu-close {
      display: none;
    }
  }
}
