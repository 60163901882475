@use "../../styles/abstracts-var-mixins/variables" as *;
@use "../../styles/abstracts-var-mixins/mixins" as *;

.myButton {
  @include paragraph-text;
  background-color: $grey-medium;
  font-size: 0.8rem;
  font-weight: $semibold-font-weight;
  padding: 0.2rem 0.5rem;
  border: 1px solid $black;
  border-radius: 0.2rem;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;

  &:hover {
    background-color: $grey-medium;
    font-weight: $semibold-font-weight;
    box-shadow: none;

    border-width: 1px 1px;
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background: $orange-light;
    box-shadow: -1px -1px 5px 0px #fff, 7px 7px 20px 0px #0003,
      4px 4px 5px 0px #0002;
    transition: 400ms ease all;
  }
  &:after {
    right: inherit;
    top: inherit;
    left: 0;
    bottom: 0;
  }
  &:hover:before,
  &:hover:after {
    width: 100%;
    transition: 800ms ease all;
  }

  &--blue {
    &:before,
    &:after {
      background-color: $orange-medium;
    }
  }
  &--railSystems {
    &:before,
    &:after {
      background-color: $orange-light;
    }
  }
  &--construction {
    &:before,
    &:after {
      background-color: $orange;
    }
  }
  &--geotechnical {
    &:before,
    &:after {
      background-color: $orange-light;
    }
  }
}
@media screen and (min-width: $extra-large-desktop-breakpoint) {
  .button {
    font-size: 1.5rem;
  }
}
