@use "../../styles/abstracts-var-mixins/variables" as *;
@use "../../styles/abstracts-var-mixins/mixins" as *;

.contact-us__banner {
  background-color: #f4f4f4;
  opacity: 1;
  background-image: radial-gradient(
      #ff8b53 1.9000000000000001px,
      transparent 1.9000000000000001px
    ),
    radial-gradient(#ff8b53 1.9000000000000001px, #f4f4f4 1.9000000000000001px);
  background-size: 76px 76px;
  background-position: 0 0, 38px 38px;
}
