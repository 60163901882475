@use "../../styles/abstracts-var-mixins/variables" as *;
@use "../../styles/abstracts-var-mixins/mixins" as *;

.navigation-menu {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  @include paragraph-text;
}

@media screen and (min-width: $extra-large-desktop-breakpoint) {
  .navigation-menu {
    justify-content: space-between;
    font-size: 1.7rem;
  }
  
}
