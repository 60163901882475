@use "../abstracts-var-mixins/variables" as *;
@use "../abstracts-var-mixins/mixins" as *;
@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Montserrat&display=swap");

h1,
h2 {
  @include header-text;
}

h3,
h4,
h5 {
  @include subheader-text;
}

p {
  @include paragraph-text;
}

@media screen and (min-width: $tablet-breakpoint) {
  :root {
    font-size: 110%;
  }
}

@media screen and (min-width: $desktop-breakpoint) {
  :root {
    font-size: 120%;
  }
}

@media screen and (min-width: $large-desktop-breakpoint) {
  :root {
    font-size: 130%;
  }
}
@media screen and (min-width: $extra-large-desktop-breakpoint) {
  :root {
    font-size: 140%;
  }
}
