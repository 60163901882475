@use "../../styles/abstracts-var-mixins/variables" as *;
@use "../../styles/abstracts-var-mixins/mixins" as *;

.contact-information {
  word-wrap: break-word;
  width: 100%;
  display: grid;
  grid-template-columns: 10% 90%;
  grid-template-rows: 4fr;
  gap: 0.3rem;
  align-items: center;
  &__header-link {
    color: $black;
    text-decoration: none;
    grid-column: 1 / 3;
  }
  &__icon {
    color: $orange-medium;
    width: 1.5rem;
    height: 1.5rem;

    grid-column: 1 / 2;
  }
  &__text {
    font-size: 0.8rem;
  }
  .contact-information__text {
    font-weight: $light-font-weight;
  }
}
