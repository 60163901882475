@use "./variables" as *;

// Text Styling mixins
@mixin large-header-text {
  font-family: $header-font-family;
  font-weight: $light-font-weight;
  font-size: $large-header-font-size;
}
// standard header
@mixin header-text {
  font-family: $header-font-family;
  font-weight: $light-font-weight;
  font-size: $header-font-size;
}
// subheader(card)
@mixin subheader-text {
  font-family: $header-font-family;
  font-size: $subheader-font-size;
  font-weight: $light-font-weight;
}
//
@mixin paragraph-text {
  font-family: $header-font-family;
  font-weight: $semibold-font-weight;
  font-size: $paragraph-font-size;
}
@mixin small-paragraph-text {
  @include paragraph-text;
  font-size: $small-paragraph-font-size;
}
