@use "../../styles/abstracts-var-mixins/variables" as *;

.social-links-container {
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  &--footer {
    justify-content: unset;
  }
  .social-links {
    margin: 0.2rem;
    &--blue {
      color: $orange-medium;
    }
    &--blue-green {
      color: $orange-medium;
    }
    &--default {
      width: 1rem;
      height: 1rem;
    }
    &--large {
      width: 2rem;
    }
  }
  &--header {
    padding: 0.1rem 2rem;
  }
}
@media screen and (min-width: $extra-large-desktop-breakpoint) {
  .social-links-container {
    .social-links {
      font-size: 2rem;
      &--default {
        width: 2rem;
        height: 2rem;
      }
    }
    //  write me a selector that is specific enough for the social links found in the header of the page
  }
  .social-links-container--header {
    .social-links-container--header {
      .social-links--default {
        font-size: 2rem;
      }
    }
  }
}
